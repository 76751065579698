/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPlainObject } from 'lodash-es';
import securify from '@shop-ware/securify';
import stringify from 'json-stringify-safe';
export const Level = {
    TRACE: 1,
    DEBUG: 2,
    INFO: 3,
    WARN: 4,
    ERROR: 5
};
export class Logger {
    name;
    level;
    constructor(name, level) {
        this.name = name;
        try {
            if (level) {
                this.level = this._toLevel(level);
            }
            else if (process?.env.GLOBAL_LOG_LEVEL) {
                this.level = this._toLevel(process.env.GLOBAL_LOG_LEVEL);
            }
            else if (process?.env[`${this.name.toUpperCase().replace(/-/g, '_')}_LOG_LEVEL`]) {
                this.level = this._toLevel(process.env[`${this.name.toUpperCase().replace(/-/g, '_')}_LOG_LEVEL`]);
            }
            else {
                this.level = Level.INFO;
            }
        }
        catch (err) {
            console.log('Could not determine log level, setting to INFO', err);
            this.level = Level.INFO;
        }
    }
    _log(requiredLevel, type, ...args) {
        if (!this._isEnabled(requiredLevel)) {
            return;
        }
        let appContext;
        let logThreadId;
        let logArgs = args;
        const logObj = {
            name: this.name,
            level: this._toLevelName(requiredLevel),
            type
        };
        const context = this._getLambdaContext(logArgs[logArgs.length - 1]);
        if (context && context.awsRequestId) {
            appContext = context['app-context'];
            logThreadId = context['app-log-thread-id'];
            logArgs = logArgs.slice(0, -1);
        }
        if (logThreadId) {
            logObj.logThreadId = logThreadId;
        }
        if (appContext) {
            const contextProps = [
                'ip',
                'auth',
                'callerId',
                'version',
                'requestId',
                'correlationId',
                'containerId',
                'function',
                'memory',
                'logGroup',
                'logStream',
                'region',
                'service',
                'stage',
                'coldstart',
                'error',
                'errorCode',
                'errorHttpStatusCode'
            ];
            contextProps.forEach(cp => {
                if (appContext[cp]) {
                    logObj[cp] = appContext[cp];
                }
            });
        }
        const errors = logArgs.filter(arg => arg?.stack && arg?.message);
        logObj.content = this._secure(logArgs.map(arg => (arg?.stack && arg?.message ? arg.message : arg)));
        this._logFunction(requiredLevel)(stringify(logObj, null, 2));
        errors.forEach(err => this._logFunction(requiredLevel)(err));
    }
    _getLambdaContext(context) {
        return context?.lambda?.context || context;
    }
    _secure(args) {
        if (!args) {
            return args;
        }
        return args.map(arg => isPlainObject(arg) || Array.isArray(arg) ? securify(arg) : arg);
    }
    _logFunction(requiredLevel) {
        switch (requiredLevel) {
            case Level.TRACE:
                return console.trace;
            case Level.DEBUG:
                return console.debug;
            case Level.INFO:
                return console.info;
            case Level.WARN:
                return console.warn;
            case Level.ERROR:
                return console.error;
        }
    }
    _isEnabled(logLevel) {
        return logLevel >= this.level;
    }
    _validate(logLevel) {
        if (logLevel < Level.TRACE || logLevel > Level.ERROR) {
            throw new Error(`Invalid log level: ${logLevel}`);
        }
        return logLevel;
    }
    _toLevel(logLevel) {
        if (typeof logLevel === 'number') {
            return this._validate(logLevel);
        }
        else if (Level[logLevel]) {
            return Level[logLevel];
        }
        else {
            throw new Error(`Invalid log level: ${logLevel}`);
        }
    }
    _toLevelName(logLevel) {
        switch (logLevel) {
            case Level.TRACE:
                return 'TRACE';
            case Level.DEBUG:
                return 'DEBUG';
            case Level.INFO:
                return 'INFO';
            case Level.WARN:
                return 'WARN';
            case Level.ERROR:
                return 'ERROR';
            default:
                return 'UNKNOWN';
        }
    }
    setLevel(lvl) {
        this.level = this._toLevel(lvl);
    }
    getLevel() {
        return this.level;
    }
    trace(...args) {
        this._log(Level.TRACE, 'log', ...args);
    }
    debug(...args) {
        this._log(Level.DEBUG, 'log', ...args);
    }
    info(...args) {
        this._log(Level.INFO, 'log', ...args);
    }
    warn(...args) {
        this._log(Level.WARN, 'log', ...args);
    }
    error(...args) {
        this._log(Level.ERROR, 'log', ...args);
    }
}
