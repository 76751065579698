import { cloneDeepWith, uniq } from 'lodash-es';
const MATCHES = [
    'pass',
    'pw',
    'key',
    'license',
    'secret',
    'credential',
    'token',
    'authorization',
    'signature',
    'cookie',
    'code',
    'answer'
];
const EXCEPTIONS = getExceptions(['routekey', 'statuscode']);
console.log('securify exceptions:', EXCEPTIONS);
export const REDACTED = '*****';
export default function (value) {
    if (isString(value)) {
        const lowerCaseValue = value.toLowerCase();
        if (MATCHES.find(match => lowerCaseValue.includes(match))) {
            return REDACTED;
        }
        return value;
    }
    return cloneDeepWith(value, (value, key) => {
        if (!value) {
            return;
        }
        if (isString(key)) {
            const lowerCaseKey = key.toLowerCase();
            if (!EXCEPTIONS.includes(lowerCaseKey) &&
                MATCHES.some(match => lowerCaseKey.includes(match))) {
                return REDACTED;
            }
        }
        if (isString(value)) {
            const lowerCaseValue = value.toLowerCase();
            if (MATCHES.find(match => lowerCaseValue.includes(match))) {
                return REDACTED;
            }
        }
    });
}
function isString(value) {
    return !!value && typeof value === 'string';
}
function getExceptions(defaults) {
    const environmentExceptions = (process?.env.SECURIFY_EXCEPTIONS || '')
        .split(',')
        .map(ee => ee.trim().toLowerCase())
        .filter(ee => !!ee);
    return uniq([...defaults, ...environmentExceptions]);
}
